<script>
import APIStore from '@app/services/API/Store';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    APIStore.getTags(this.$store.getters['auth/store'].uuid)
      .then((tags) => this.existingTags = tags);
  },
  data: () => ({
    query: '',
    existingTags: [],
  }),
  methods: {
    onTyping(query) {
      this.query = query;
    },
  },
  computed: {
    options() {
      const filteredOptions = this.existingTags.filter((tag) => tag.toLowerCase().includes(this.query.toLowerCase()));

      return filteredOptions;
    },
  },
};
</script>

<template>
  <b-taginput :value="value" @input="$emit('input', $event)" :data="options" autocomplete @typing="onTyping"
    type="is-primary" v-bind="$attrs" />
</template>
