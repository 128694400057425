<script>
import TrainingGeneralSettings from '@app/components/TrainingGeneralSettings.vue';
import TrainingTeachersForm from '@app/components/TrainingTeachersForm.vue';
import TrainingSEOSettings from '@app/components/TrainingSEOSettings.vue';
import TrainingAfterRegisterForm from '@app/components/TrainingAfterRegisterForm.vue';
import TrainingAccessSettings from '@app/components/TrainingAccessSettings.vue';
import TrainingNbCustomersMaxSettings from '@app/components/TrainingNbCustomersMaxSettings.vue';
import TrainingAutomationsList from '@app/components/TrainingAutomationsList.vue';
import TrainingWebhooksForm from '@app/components/TrainingWebhooksForm.vue';
import TrainingCommentsForm from '@app/components/TrainingCommentsForm.vue';
import TrainingInternalMenuForm from '@app/components/TrainingInternalMenuForm.vue';
import TrainingInternalFeedbackForm from '@app/components/TrainingInternalFeedbackForm.vue';
import TrainingTagsForm from '@app/components/TrainingTagsForm.vue';

export default {
  components: {
    TrainingGeneralSettings,
    TrainingTeachersForm,
    TrainingSEOSettings,
    TrainingAfterRegisterForm,
    TrainingAccessSettings,
    TrainingNbCustomersMaxSettings,
    TrainingAutomationsList,
    TrainingWebhooksForm,
    TrainingCommentsForm,
    TrainingInternalMenuForm,
    TrainingInternalFeedbackForm,
    TrainingTagsForm,
  },
  head: {
    title: 'Paramètres',
  },
};
</script>

<template>
  <div>
    <AppToggleSection>
      <template #title>
        Général
      </template>
      <template #default>
        <TrainingGeneralSettings />
        <TrainingNbCustomersMaxSettings />
      </template>
    </AppToggleSection>
    <AppToggleSection v-if="$store.getters['auth/permissions'].canManageUsers">
      <template #title>
        Accès formateurs
      </template>
      <template #default>
        <TrainingTeachersForm />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        SEO (référencement)
      </template>
      <template #default>
        <TrainingSEOSettings />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        Après achat/inscription
      </template>
      <template #default>
        <TrainingAfterRegisterForm />
        <TrainingAccessSettings />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        Espace apprenant
      </template>
      <template #default>
        <TrainingCommentsForm />
        <TrainingInternalMenuForm />
        <TrainingInternalFeedbackForm />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        <span style="display:flex;gap:8px;align-items: center;">
          Étiquettes <span class="tag is-primary ml-3">Nouveau</span>
        </span>
      </template>
      <template #default>
        <TrainingTagsForm />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        Autorépondeur &amp; automatisation
      </template>
      <template #default>
        <TrainingAutomationsList />
        <TrainingWebhooksForm />
      </template>
    </AppToggleSection>
  </div>
</template>
