<script>
import { currentTrainingForms } from '@app/mixins/forms';
import APIStore from '@app/services/API/Store';

export default {
  mixins: [currentTrainingForms],
  i18n: {
    messages: {
      fr: {
        test: {
          succeeded: `
            <strong>Test de l'envoi réussi !</strong>
            <br>
            Vérifiez les données reçues chez votre fournisseur de WebHook
            (Zapier, Make...).
          `,
        },
      },
    },
  },
  data: () => ({
    training: {
      feature_options: {
        webhooks: {
          customer_enrolled: '',
          training_completed: '',
          training_item_completed: '',
        },
      },
    },
  }),
  methods: {
    testWebhook(webhook) {
      const url = this.training.feature_options.webhooks[webhook];

      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      APIStore.testWebhook(this.authStore.uuid, { url, webhook })
        .then(() => this.$buefy.dialog.alert(this.$t('test.succeeded')))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Configurez vos WebHooks
      avec Zapier, Make...
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Vous avez envie de créer des
        automatisations ou procédures ?
      </p>
      <p>
        Les WebHooks (crochets web)
        sont ce qu'il vous faut !
        Teachizy enverra des informations
        à <strong>Zapier</strong>, <strong>Make (anciennement Integromat)</strong> ou
        tout <strong>autre service</strong>
        qui fournit une URL <strong>de WebHook</strong>.
      </p>
      <p>
        Pour commencer à automatiser vos procédures,
        vous pouvez suivre notre tutoriel en
        <a
          class="tdecoration-underline has-text-weight-bold"
          href="https://youtu.be/Fw39W9eMTd0"
          target="_blank"
          rel="noreferrer noopener">
          cliquant ici
        </a>
      </p>
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <GoProWrapper :has-sep="false">
          <div class="columns is-multiline is-align-items-flex-end">
            <div class="column is-12 mb-0">
              <b-field label="WebHook déclenché à l'inscription d'un apprenant">
                <b-input
                  placeholder="Entrez une URL"
                  pattern="^https?://.+"
                  expanded
                  v-model="training.feature_options.webhooks.customer_enrolled"
                />
                <p class="control">
                  <b-tooltip position="is-top" multilined>
                    <template #content>
                      Cliquez pour envoyer des données de test
                      au WebHook, afin de valider son bon fonctionnement
                    </template>
                    <b-button
                      class="has-text-weight-bold"
                      :disabled="!training.feature_options.webhooks.customer_enrolled"
                      expanded
                      @click="testWebhook('customer_enrolled')"
                    >
                      Tester le webhook
                    </b-button>
                  </b-tooltip>
                </p>
              </b-field>
            </div>
            <div class="column is-12 mb-0">
              <b-field label="WebHook déclenché lorsqu'un apprenant termine une formation">
                <b-input
                  placeholder="Entrez une URL"
                  pattern="^https?://.+"
                  expanded
                  v-model="training.feature_options.webhooks.training_completed"
                />
                <p class="control">
                  <b-tooltip position="is-top" multilined>
                    <template #content>
                      Cliquez pour envoyer des données de test
                      au WebHook, afin de valider son bon fonctionnement
                    </template>
                    <b-button
                      class="has-text-weight-bold"
                      :disabled="!training.feature_options.webhooks.training_completed"
                      expanded
                      @click="testWebhook('training_completed')"
                    >
                      Tester le webhook
                    </b-button>
                  </b-tooltip>
                </p>
              </b-field>
            </div>
            <div class="column is-12 mb-0">
              <b-field label="WebHook déclenché lorsqu'un apprenant termine une leçon">
                <b-input
                  placeholder="Entrez une URL"
                  pattern="^https?://.+"
                  expanded
                  v-model="training.feature_options.webhooks.training_item_completed"
                />
                <p class="control">
                  <b-tooltip position="is-top" multilined>
                    <template #content>
                      Cliquez pour envoyer des données de test
                      au WebHook, afin de valider son bon fonctionnement
                    </template>
                    <b-button
                      class="has-text-weight-bold"
                      :disabled="!training.feature_options.webhooks.training_item_completed"
                      expanded
                      @click="testWebhook('training_item_completed')"
                    >
                      Tester le webhook
                    </b-button>
                  </b-tooltip>
                </p>
              </b-field>
            </div>
          </div>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </GoProWrapper>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
