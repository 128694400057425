<script>
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  data: () => ({
    training: {
      feature_options: {
        is_menu_sections_opened: true,
      },
    },
  }),
  methods: {
    onEnableChange() {
      if (this.dataIsValid(this.isLoading)) {
        this.handle()
          .catch((error) => {
            const state = this.training.feature_options.is_menu_sections_opened;
            this.training.feature_options.is_menu_sections_opened = !state;
            this.$errorHandlers.axios(error);
          });
      }
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Affichage du menu
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Vous avez beaucoup de leçons et de chapitres dans votre formation ?
      </p>
      <p>
        Dans ce cas, le menu de navigation peut vite
        devenir long à parcourir. En cochant cette option, vos apprenants verront
        par défaut les chapitres fermés, afin d'avoir un menu de navigation moins long.
      </p>
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <GoProWrapper :has-sep="false">
          <b-field>
            <b-switch v-model="training.feature_options.is_menu_sections_opened" @input="onEnableChange">
              Laisser les chapitres ouverts dans le menu
            </b-switch>
          </b-field>

          <b-loading v-if="isLoading" active />
        </GoProWrapper>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
