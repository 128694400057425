<script>
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  data: () => ({
    enableNbCustomersMax: false,
    training: {
      feature_options: {
        nb_customers_max: null,
      },
    },
  }),
  methods: {
    onEnableChange() {
      if (!this.enableNbCustomersMax) {
        this.training.feature_options.nb_customers_max = null;
      }
    },
  },
  created() {
    this.enableNbCustomersMax = this.training.feature_options.nb_customers_max != null;
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Limitez le nombre d'inscriptions à cette formation
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Vous voulez limiter l'accès à votre formation,
        une fois un certain nombre d'inscriptions atteint ?
      </p>
      <p>
        Activez la limitation du nombre d'inscription et précisez le nombre souhaité.
        Lorsque celui-ci est atteint, vos prospects ne pourront plus s'inscrire
        mais vous pourrez toujours ajouter des apprenants manuellement.
      </p>
      <p>
        Cette configuration s'appliquera uniquement lors de l'acquisition directe de cette formation
        (cela ne concerne donc pas les packs).
      </p>
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <GoProWrapper :has-sep="false">
          <b-field>
            <b-switch v-model="enableNbCustomersMax" @input="onEnableChange">
              Activer une limitation du nombre d'apprenants
            </b-switch>
          </b-field>
          <b-field v-if="enableNbCustomersMax" label="Entrez le nombre maximum d'apprenants">
            <b-input
              type="number"
              min="1"
              max="10000"
              expanded
              required
              v-model.number="training.feature_options.nb_customers_max"
            />
          </b-field>
          <div class="field has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </div>
        </GoProWrapper>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
