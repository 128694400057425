<script>
import APITrainings from '@app/services/API/Trainings';

export default {
  messages: {
    label: {
      SENDINBLUE: 'ID de la liste Brevo (ex Sendinblue), sans le #',
      ACTIVE_CAMPAIGN: 'Identifiant "listid" de la liste ActiveCampaign',
      GET_RESPONSE: 'Clé de la liste GetResponse (ex: "AbcDe")',
    },
  },
  data: () => ({
    providers: null,
    isLoading: false,
  }),
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    lists() {
      return this.currentTraining.email_marketing_lists;
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const data = { ...this.lists };
      APITrainings.updateEmailMarketingLists(this.currentTraining.uuid, data)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div
    v-if="$store.getters['auth/isNotPercentPlan'] && lists.length"
    class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Gérez vos listes pour le Marketing
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
      <p>
        Ajoutez vos apprenants à une liste de votre outil Marketing.
      </p>
      <p>
        Les attributs <strong>NOM</strong>, <strong>PRENOM</strong> et <strong>EMAIL</strong>
        sont envoyés automatiquement à votre outil Marketing
        lors de l'acquisition de votre formation par un apprenant.
      </p>
      <p>
        Nous envoyons également l'attribut <strong>NOM_FORMATION</strong>
        uniquement si votre outil Marketing est Brevo (anciennement Sendinblue). Il s'agit d'un
        attribut facultatif de type "texte" qu'il vous faut ajouter à vos attributs Brevo.
      </p>
      <p>
        Gérez vos clés d'API dans la page
        <router-link :to="{name: 'marketing_automations'}">
          Outils Marketing.
        </router-link>
      </p>
    </div>
    <div class="column is-8">
      <form
        class="box"
        @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field
          v-for="one in lists"
          :key="one.provider"
          :label="$options.messages.label[one.provider]">
          <b-input v-model="one.list_id" />
        </b-field>
        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
