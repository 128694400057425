<script>
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  data: () => ({
    training: {
      feature_options: {
        redirect: '',
      },
    },
  }),
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Configurez votre page de redirection
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Votre apprenant vient de rejoindre votre formation ;
        vous pouvez l'accueillir plus efficacement en le redirigeant
        vers la page de votre choix :
      </p>
      <ul>
        <li>Page de remerciement</li>
        <li>Page explicative d'une démarche à suivre</li>
        <li>Sondage ou formulaire complémentaire</li>
        <li>... Ou tout autre élément d'onboarding (d'intégration) que vous jugerez utile pour votre formation !</li>
      </ul>
      <!-- <p>
        Pour commencer à automatiser vos procédures,
        vous pouvez suivre notre tutoriel en
        <a
          class="tdecoration-underline has-text-weight-bold"
          href="https://youtu.be/Fw39W9eMTd0"
          target="_blank"
          rel="noreferrer noopener">
          cliquant ici
        </a>
      </p> -->
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <GoProWrapper :has-sep="false">
          <b-field label="Lien de redirection après achat/inscription">
            <b-input
              placeholder="Entrez une URL"
              pattern="^https?://.+"
              expanded
              v-model="training.feature_options.redirect"
            />
          </b-field>
          <div class="field has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </div>
        </GoProWrapper>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
