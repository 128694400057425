<script>
import { toBase64 } from '@shared/services/Utils';
import { refreshCurrentTrainingOnUpdate } from '@app/mixins/forms';

export default {
  mixins: [refreshCurrentTrainingOnUpdate],
  forms: [
    {
      name: {
        label: 'Titre',
        type: 'text',
        column: 'is-12',
        inputAttrs: {
          required: true,
          maxlength: 128,
          hasCounter: false,
        },
      },
      slug: {
        label: 'URL (pour le SEO)',
        type: 'text',
        column: 'is-12',
        hasAddonLeft: true,
        hasAddonRight: true,
        inputAttrs: {
          maxlength: 128,
          hasCounter: false,
          expanded: true,
        },
      },
      billing_address_enabled: {
        switchLabel: 'Demander l\'adresse de facturation lors des achats',
        type: 'switch',
        column: 'is-12',
        tooltip: `
          L'adresse de facturation ne sera demandée que lorsque la formation est payante.
        `,
        tooltipAttrs: {
          multilined: true,
        },
      },
      program_enabled: {
        switchLabel: 'Afficher la liste des leçons sur votre page de formation',
        type: 'switch',
        column: 'is-12',
      },
      program_toggled: {
        switchLabelHTML: 'Afficher la liste des leçons <strong>depliée</strong> par défaut',
        type: 'switch',
        column: 'is-12',
        ifModelName: 'program_enabled',
        ifModelValue: true,
      },
      picture: {
        label: 'Image',
        type: 'upload',
        inputAttrs: { accept: 'image/*' },
        column: 'is-6',
      },
    },
  ],
  data() {
    return {
      isLoading: {
        0: false,
        1: false,
      },
      training: {
        name: '',
        slug: '',
        feature_options: {
          billing_address: {
            enabled: false,
          },
        },
        program_enabled: false,
        program_toggled: false,
        picture: '',
      },
    };
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    trainingIsDraft() {
      return this.currentTraining.status === 'DRAFT';
    },
    trainingUrl() {
      const p = this.trainingIsDraft ? `?p=${this.$store.state.auth.previewToken}` : '';

      return `${this.$store.getters['auth/url']}/formations/${this.training.slug}${p}`;
    },
  },
  created() {
    this.hydrateTraining();
  },
  methods: {
    hydrateTraining() {
      Object.keys(this.training)
        .forEach((k) => (this.training[k] = this.currentTraining[k]));
    },
    onViewDraft(event) {
      if (this.trainingIsDraft) {
        event.preventDefault();
        this.$buefy.dialog.confirm({
          title: 'Formation en brouillon !',
          message: `
            Votre formation est en brouillon. <br>
            Vous pouvez la prévisualiser, mais personne d'autre n'y aura accès.
          `,
          type: 'is-warning',
          focusOn: 'cancel',
          onConfirm: () => window.open(this.trainingUrl),
        });
      }
    },
    handlePicture(file) {
      if (!file) {
        this.training.picture = null;
        return;
      }

      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form[1] });
      toBase64(file)
        .then((base64) => (this.training.picture = base64))
        .finally(() => loader.close());
    },
    handle(form, fk) {
      const training = {};

      Object.keys(form)
        .forEach((k) => (training[k] = this.training[k]));

      training.feature_options = { ...this.training.feature_options };

      if (training.picture && training.picture.startsWith('http')) {
        delete training.picture;
      }

      this.isLoading[fk] = true;
      this.$store.dispatch('trainings/update', { uuid: this.currentTraining.uuid, training, isCurrent: true })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading[fk] = false));
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Paramètres
    </template>
    <template #content>
      <p>
        Détaillez tous les aspects généraux de votre formation comme le titre, l'url
        ou encore l'image de mise en avant.
      </p>
      <p>
        Attention ! Si vous modifiez l'URL de votre formation, n'oubliez d'en informer vos apprenants.
      </p>
      <p>
        Selon vos paramétrages, la liste des leçons peut ou non être affichée
        dans la page publique de votre formation. Sachez toutefois que les chapitres vides
        (sans leçons) seront automatiquement masqués.
      </p>
    </template>

    <template #form>
      <form
        v-for="(form, fk) in $options.forms"
        :key="(form, fk)"
        ref="form"
        class="box"
        @submit.prevent="dataIsValid(isLoading[fk]) && handle(form, fk)"
      >
        <div class="columns is-multiline">
          <div
            v-for="(field, k) in form"
            v-show="field.ifModelName == null || training[field.ifModelName] == field.ifModelValue"
            :key="k"
            class="column"
            :class="field.column">
            <BaseField
              v-if="k == 'billing_address_enabled'"
              v-model="training.feature_options.billing_address.enabled"
              :field="field"
            />
            <BaseField
              v-else-if="k == 'picture'"
              :field="field"
              :value="training[k]"
              @input="handlePicture"
            />
            <BaseField v-else-if="k == 'slug'" v-model="training[k]" :field="field">
              <template #addonLeft>
                <span class="button is-static">
                  {{ $store.getters['auth/domain'] }}/formations/
                </span>
              </template>
              <template #addonRight>
                <a
                  class="button is-primary"
                  :href="trainingUrl"
                  target="_blank"
                  @click="onViewDraft"
                >
                  <b-icon icon="link" />
                </a>
              </template>
              <template #help>
                C'est le lien d'accès à votre formation Teachizy.
                <a
                  class="tdecoration-underline"
                  :href="trainingUrl"
                  target="_blank"
                  @click="onViewDraft">
                  Cliquez ici pour y accéder
                </a>
              </template>
            </BaseField>
            <BaseField
              v-else
              v-model="training[k]"
              :field="field"
            />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading[fk]">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
