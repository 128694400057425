<script>
import cloneDeep from 'lodash/cloneDeep';
import feedbackIcons from '@shared/assets/data/feedback_icons.json';
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  static: { feedbackIcons },
  data: () => ({
    hasIconSelectorOpen: false,
    trainingActionName: 'trainings/updateFeedback',
    training: {
      feedback_options: {
        enabled: false,
        options: {},
      },
    },
  }),
  computed: {
    hasExpertPlan() {
      return this.$store.getters['auth/hasExpertPlan'];
    },
    iconsList() {
      return this.getIconsList();
    },
  },
  methods: {
    getIconsList(type) {
      return this.$options
        .static
        .feedbackIcons[type || this.training.feedback_options.options.type];
    },
    showIconSelector() {
      this.hasIconSelectorOpen = true;
    },
    selectIcons(icons) {
      const { values } = this.training.feedback_options.options;

      Object.keys(values).forEach((v) => {
        values[v].icon = icons[v].icon;
        values[v].icon_type = icons[v].icon_type;
      });

      this.hasIconSelectorOpen = false;
    },
    setDefaultLabelAndValues(type) {
      let labelAndValues;

      if (!type) {
        type = this.training.feedback_options.options.type;
      }

      const iconsList = this.getIconsList(type);

      if (type === 'BINARY') {
        labelAndValues = {
          label: 'Comment avez-vous trouvé cette leçon ?',
          values: cloneDeep(iconsList[0]),
        };

        labelAndValues.values.k1.text = 'Instructive';
        labelAndValues.values.k2.text = 'Pas évidente';
      } else if (type === 'REACTION') {
        // TODO: handle reactions
        labelAndValues = {};
      } else {
        labelAndValues = {
          label: 'Comment évaluez-vous ce contenu ?',
          values: cloneDeep(iconsList[0]),
        };

        labelAndValues.values.k1.text = '1';
        labelAndValues.values.k2.text = '2';
        labelAndValues.values.k3.text = '3';
        labelAndValues.values.k4.text = '4';
        labelAndValues.values.k5.text = '5';
      }

      this.training.feedback_options.options.label = labelAndValues.label;
      this.training.feedback_options.options.values = labelAndValues.values;
    },
    onTypeChange(newType) {
      const oldType = this.training.feedback_options.options.type;

      if (newType === oldType) {
        return;
      }

      this.training.feedback_options.options.type = newType;

      if (!this.hasExpertPlan && newType !== 'RANGE') {
        this.$showMessage.goPro(null, null, {
          plan: 'EXPERT',
        });

        this.$nextTick(() => (this.training.feedback_options.options.type = oldType));

        return;
      }

      this.$buefy.dialog.confirm({
        title: 'Attention !',
        type: 'is-danger',
        message: `
          <p>
            Si vous changez le type, toutes les notes ou appréciations
            existantes de vos apprenants sur les leçons de cette formation
            seront perdues.
          </p>
          <p class="mt-3">
            Vous perdrez aussi tous les paramètres du type actuel.
          </p>
        `,
        focusOn: 'cancel',
        confirmText: 'Confirmer le changement',
        onConfirm: () => this.setDefaultLabelAndValues(newType),
        onCancel: () => {
          this.$nextTick(() => (this.training.feedback_options.options.type = oldType));
        },
      });
    },
    onCommentOptionChange() {
      if (!this.hasExpertPlan) {
        this.$showMessage.goPro(null, null, {
          plan: 'EXPERT',
        });

        this.$nextTick(() => (this.training.feedback_options.options.comment_enabled = false));
      }
    },
    handle() {
      const feedbackOptions = { ...this.training.feedback_options };

      this.isLoading = true;
      return this.$store.dispatch('trainings/updateFeedbackOptions', {
        uuid: this.currentTraining.uuid,
        feedbackOptions,
        isCurrent: true,
      })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    if (!this.training.feedback_options.options.values) {
      this.setDefaultLabelAndValues();
    }
  },
  updated() {
    if (!this.training.feedback_options.options.values) {
      this.setDefaultLabelAndValues();
    }
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Satisfaction à chaud
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Vos apprenants apprécient-ils vos leçons ? Découvrez-le grâce à la satisfaction à chaud !
      </p>
      <p>
        En activant cette option, vos apprenants pourront partager avec vous
        leurs impressions sur chaque leçon de votre formation.
      </p>
      <p>
        Cela fait d'ailleurs partie des critères Qualiopi !
      </p>
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="field">
          <b-switch v-model="training.feedback_options.enabled">
            Activer la satisfaction à chaud
          </b-switch>
        </div>

        <template v-if="training.feedback_options.enabled">
          <hr>

          <b-field class="mb-5" label="De quelle façon souhaitez-vous obtenir la satisfaction à chaud ?">
            <b-select :value="training.feedback_options.options.type" expanded @input="onTypeChange">
              <option value="RANGE">
                Par notation
              </option>
              <option value="BINARY">
                Par appréciation
                <template v-if="!hasExpertPlan">
                  (EXPERT)
                </template>
              </option>
              <!-- <option value="REACTION">
                Personnalisé (EXPERT)
              </option> -->
            </b-select>
          </b-field>

          <b-field
            class="mb-5"
            label="Permettre à l'apprenant de laisser un avis (uniquement visible par vous)"
          >
            <b-switch
              v-model="training.feedback_options.options.comment_enabled"
              @input="onCommentOptionChange"
            >
              Oui
            </b-switch>
          </b-field>

          <b-field class="mb-5">
            <template #label>
              Libellé
              <template v-if="!hasExpertPlan">
                (EXPERT)
              </template>
            </template>
            <b-tooltip class="w-full" :active="!hasExpertPlan">
              <template #content>
                Il faut le pack EXPERT pour éditer ce texte.
              </template>
              <b-input
                v-model="training.feedback_options.options.label"
                :disabled="!hasExpertPlan"
                required
                expanded
              />
            </b-tooltip>
          </b-field>

          <h3 class="title is-6 mb-2">
            Prévisualisation
          </h3>

          <div class="field">
            <div class="notification is-info is-light has-text-centered">
              <b-tooltip
                v-for="(v, k) in training.feedback_options.options.values"
                :key="k"
                :label="v.text"
              >
                <b-button
                  class="feedback-btn h-auto py-0 px-3 is-size-4 has-anime-scale"
                  type="is-text"
                  :icon-left="v.icon"
                  :icon-pack="v.icon_type"
                />
              </b-tooltip>

              <p class="mt-3">
                <b-button
                  class="tdecoration-underline"
                  type="is-text"
                  @click="showIconSelector"
                >
                  Changer d'icônes
                </b-button>
              </p>
            </div>
          </div>

          <h3
            v-if="training.feedback_options.options.type != 'RANGE'"
            class="title is-6 mb-2"
          >
            Modifier le libellé de chaque appréciation
          </h3>

          <GoProWrapper
            v-if="training.feedback_options.options.type != 'RANGE'"
            class="field"
            :has-sep="false"
            planLock="EXPERT">
            <div class="is-flex">
              <b-field
                v-for="(v, k, i) in training.feedback_options.options.values"
                :key="k"
                :class="{'ml-3': i != 0}"
                :label="`Libellé ${i + 1}`"
              >
                <p class="control">
                  <span class="button px-5 py-1 h-auto is-static is-size-5">
                    <b-icon :icon="v.icon" :pack="v.icon_type" />
                  </span>
                </p>
                <b-input v-model="v.text" required expanded />
              </b-field>
            </div>
          </GoProWrapper>
        </template>

        <div class="field has-text-right">
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          >
            Mettre à jour
          </b-button>
        </div>
      </form>

      <b-modal
        v-if="hasIconSelectorOpen"
        v-model="hasIconSelectorOpen"
        width="460px"
      >
        <div class="box content has-text-centered">
          <h2 class="title is-5">
            Sélectionnez votre pack d'icônes
          </h2>
          <p v-for="(icons, i) in iconsList" :key="i">
            <b-tooltip label="Sélectionner">
              <b-button
                class="feedback-btns h-auto p-3"
                @click="selectIcons(icons)"
              >
                <b-icon
                  v-for="(icon, j) in icons"
                  :key="j"
                  type="is-dark"
                  class="is-size-4 py-1 mx-1"
                  :icon="icon.icon"
                  :pack="icon.icon_type"
                />
              </b-button>
            </b-tooltip>
          </p>
        </div>
      </b-modal>
    </template>
  </AppFeatureWrapper>
</template>
