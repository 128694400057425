<script>
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  data: () => ({
    enableExpiration: false,
    training: {
      feature_options: {
        expiration_in_days: null,
      },
    },
  }),
  methods: {
    onEnableChange() {
      if (!this.enableExpiration) {
        this.training.feature_options.expiration_in_days = null;
      }
    },
  },
  created() {
    this.enableExpiration = this.training.feature_options.expiration_in_days != null;
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Limitez la durée d'accès à la formation
      <AppFeatureBadge class="ml-2" position="is-right" />
    </template>
    <template #content>
      <p>
        Besoin de bloquer automatiquement l'accès aux apprenants après quelques jours ?
      </p>
      <p>
        Pour cela, activez un délai d'expiration : vos contenus de formation ne seront
        accessibles que X jours après l'achat de vos apprenants.
      </p>
    </template>
    <template #form>
      <form ref="form" class="box is-relative" @submit.prevent="dataIsValid(isLoading) && handle()">
        <GoProWrapper :has-sep="false">
          <b-field>
            <b-switch v-model="enableExpiration" @input="onEnableChange">
              Activer un délai d'expiration
            </b-switch>
          </b-field>
          <b-field v-if="enableExpiration" label="Entrez le nombre de jours">
            <b-input
              type="number"
              min="1"
              expanded
              required
              v-model.number="training.feature_options.expiration_in_days"
            />
          </b-field>
          <div class="field has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </div>
        </GoProWrapper>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
