<script>
import APIUser from '@app/services/API/User';
import APITrainings from '@app/services/API/Trainings';

export default {
  data: () => ({
    isLoading: false,
    isFetching: false,
    users: [],
    newAuthors: [],
  }),
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    hasUserAccounts() {
      return this.$store.getters['auth/store'].user_account_count > 0;
    },
    authors() {
      const currentUUID = this.$store.getters['auth/user'].uuid;
      const currentTrainingAuthorsUuids = this.currentTraining.coauthors.map((v) => v.uuid);

      return this.users.filter((user) => (
        user.uuid !== currentUUID
        && !currentTrainingAuthorsUuids.includes(user.uuid)
        && user.role === 'TEACHER'
      ));
    },
  },
  methods: {
    loadUsers() {
      if (!this.hasUserAccounts) {
        return;
      }

      this.isFetching = true;

      APIUser.getUsers()
        .then(({ data }) => (this.users = data))
        .finally(() => (this.isFetching = false));
    },
    deleteAuthor(author) {
      this.$buefy.dialog.confirm({
        message: `
          Confirmez-vous la suppression de ${author.firstname}
          de la liste des formateurs supplémentaires ?
        `,
        confirmText: 'Oui',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$refs.form });
          APITrainings.removeAuthors(this.currentTraining.uuid, [author.uuid])
            .then(({ data }) => {
              this.$store.commit('trainings/setCurrent', data);
              this.$showMessage.success();
            })
            .finally(() => loader.close());
        },
      });
    },
    handle() {
      this.isLoading = true;
      const newAuthors = [...this.newAuthors];
      APITrainings.addAuthors(this.currentTraining.uuid, newAuthors)
        .then(({ data }) => {
          this.$store.commit('trainings/setCurrent', data);
          this.$showMessage.success();
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.loadUsers();
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Gérez les formateurs
    </template>
    <template #content>
      <p>
        Vous pouvez ajouter ou retirer des formateurs supplémentaires qui auront le droit
        de modifier cette formation.
      </p>
      <p>
        Tous les membres avec le rôle Administrateur peuvent aussi gérer
        cette formation.
      </p>
    </template>
    <template #form>
      <div ref="form" class="box is-relative">
        <b-skeleton v-if="isFetching" height="200" />
        <div v-else>
          <div class="content">
            <h3 class="is-size-5">
              Formateur principal
            </h3>
            <p>
              {{ currentTraining.author.firstname }} {{ currentTraining.author.lastname }}
            </p>

            <template v-if="currentTraining.coauthors.length >= 1">
              <h3 class="is-size-5">
                Formateur(s) supplémentaire(s)
              </h3>
              <ul>
                <li v-for="author in currentTraining.coauthors" :key="author.uuid">
                  {{ author.firstname }} {{ author.lastname }} -
                  <a class="has-text-danger" href="#" @click.prevent="deleteAuthor(author)">
                    Supprimer
                  </a>
                </li>
              </ul>
            </template>
          </div>
        </div>

        <hr>

        <h3 class="title is-5">
          Ajouter des formateurs supplémentaires
        </h3>
        <form v-if="authors.length" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <BaseMultipleSelect label="Sélectionnez..." v-model="newAuthors" required>
              <BaseMultipleSelectOption
                v-for="author in authors"
                :key="author.uuid"
                :value="author.uuid">
                {{ author.firstname }} {{ author.lastname }}
              </BaseMultipleSelectOption>
            </BaseMultipleSelect>
          </b-field>
          <div class="field has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Ajouter
            </b-button>
          </div>
        </form>
        <div v-else class="notification content">
          <p>
            Vous n'avez aucun autre membre d'équipe avec le rôle formateur.
            <br>
            Vous pouvez ajouter des membres d'équipe en
            <router-link :to="{name: 'settings_team'}">cliquant ici</router-link>.
          </p>
        </div>
      </div>
    </template>
  </AppFeatureWrapper>
</template>
