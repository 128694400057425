<script>
import { currentTrainingForms } from '@app/mixins/forms';

export default {
  mixins: [currentTrainingForms],
  form: {
    comments_status: {
      label: 'Commentaires',
      type: 'select',
      column: 'is-12',
      options: {
        ON: 'Activés : autoriser et afficher les commentaires',
        OFF: 'Désactivés : ne pas afficher les commentaires',
        LOCKED: 'Bloqués : afficher les commentaires existants mais interdire les nouveaux',
      },
    },
  },
  data: () => ({
    training: {
      comments_status: 'ON',
    },
  }),
};
</script>
<template>
  <AppFeatureWrapper tag="section">
    <template #title>
      Gérez les commentaires
    </template>
    <template #content>
      <p>
        Les commentaires sont un très bon moyen de créer une communauté
        et de dynamiser votre formation.
      </p>
      <p class="has-text-weight-bold">
        Gérez ici la visibilité des commentaires de cette formation.
      </p>
      <p>
        Veillez à avoir l'option de commentaire activée pour votre espace.
        Vérifiez si c'est le cas en <router-link :to="{name: 'trainings_settings'}">
        cliquant ici
      </router-link>.
      </p>
    </template>
    <template #form>
      <div
        v-if="authStore.feature_options.comments.disabled"
        class="box is-warning content has-text-centered"
      >
        <p>
          Les commentaires sont désactivés pour votre espace.
        </p>
        <p>
          <b-button tag="router-link" type="is-primary" :to="{name: 'trainings_settings'}">
            Modifier ce paramètre
          </b-button>
        </p>
      </div>
      <form v-else class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <BaseField
          v-for="(field, k) in $options.form"
          :key="k"
          v-model="training[k]"
          :field="field"
        />
        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
